import axios from 'axios';
import TelehealthPlan from 'model/TelehealthPlan';

import { baseApi } from 'utils/apiUtil';
import { modifyAndEncodeBenefitClassNamesToHandleComma } from 'utils/commonUtil';
import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  http,
} from 'utils/httpUtil';
const planUrl = baseApi + '/v1/plans';

export const getTelehealthPlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return http.get(`${planUrl}/telehealth/roster`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-classes':
        modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(','),
    },
  });
};

export const updateTelehealthRxPlanResources = (payload: TelehealthPlan) => {
  const newPayload = { ...payload };
  const formData = new FormData();
  const entries = Object.entries(newPayload);
  entries.forEach((item) => {
    if (
      item[1] !== null &&
      item[1] !== undefined &&
      (item[0] === 'file' || Object.keys(item[1]).length !== 0)
    ) {
      formData.append(item[0], item[1]);
    }
  });

  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    withCredentials: true,
  };
  return axios.put(`${planUrl}/telehealth/${payload.id}`, formData, config);
};
