import { FC, MutableRefObject, useContext } from 'react';

import isEmpty from 'lodash/isEmpty';
import { baseApi } from 'utils/apiUtil';
import { addHttp } from 'utils/httpUtil';
import iconEdit from 'images/icon-edit.svg';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PlanDocuments from 'modules/resources/components/PlanDocuments/PlanDocuments';
import {
  BASIC_LIFE_AND_ADD_DOCS_ORDER,
  DV_DOCS_ORDER,
  DV_PLAN_DOCUMENT_TYPE,
  LIFE,
  LIFE_DOCUMENT_TYPE,
  LTD_DOCS_ORDER,
  LTD_DOCUMENT_TYPE,
  MEDICAL_DOCS_ORDER,
  MEDICAL_PLAN_DOCUMENT_TYPE,
  PLAN_DOCUMENT,
  RETIREMENT_DOCS_ORDER,
  RETIREMENT_DOC_TYPE,
  STD_DOCS_ORDER,
  STD_DOCUMENT_TYPE,
  TAX_ADVANTAGED_ACCOUNT,
  TAX_ADVANTAGED_DOCS_ORDER,
  TAX_ADVANTAGED_DOC_TYPE,
  TAX_ADVANTAGED_TYPE,
  VOL_LIFE_DOCS_ORDER,
  VOL_LIFE_DOCUMENT_TYPE,
  PLAN_SUMMARY,
  ALL_DOCUMENT_TYPE,
} from 'modules/resources/constants';
import {
  BENEFIT_CATEGORY,
  TELEHEALTH_BENEFITS_KIND,
  VOL_BENEFITS_KIND,
} from 'constants/commonConstants';
import {
  LIFE_PLAN_TYPES,
  VOLUNTARY_LIFE_PLAN_TYPES,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import { NotificationContext } from 'context/NotificationContext';
import {
  convertToOptions,
  convertToWebLinkType,
  isVolBenefitKind,
} from 'utils/commonUtil';
import { trackEvents } from 'utils/initGA4';

import WebLinkType from 'model/WebLinkType';
import Option from 'model/Option';
import styles from './resourceCard.module.less';

type ResourceCardProps = {
  resource: any;
  wrappedRef?: MutableRefObject<any>;
};

const ResourceCard: FC<ResourceCardProps> = (props: ResourceCardProps) => {
  const { resource } = props;
  const notificationFlag = useContext(NotificationContext);

  const getDocumentTypes = (benefitCategory: string) => {
    switch (benefitCategory) {
      case BENEFIT_CATEGORY.MEDICAL.value:
        return MEDICAL_PLAN_DOCUMENT_TYPE;
      case BENEFIT_CATEGORY.DENTAL.value:
      case BENEFIT_CATEGORY.VISION.value:
        return DV_PLAN_DOCUMENT_TYPE;
      case LIFE_PLAN_TYPES.BASIC_ADD.value:
      case LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value:
      case LIFE_PLAN_TYPES.BASIC_LIFE.value:
        return LIFE_DOCUMENT_TYPE;
      case LIFE_PLAN_TYPES.STD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value:
        return { ...LIFE_DOCUMENT_TYPE, ...STD_DOCUMENT_TYPE };
      case LIFE_PLAN_TYPES.LTD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value:
        return { ...LIFE_DOCUMENT_TYPE, ...LTD_DOCUMENT_TYPE };
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value:
        return { ...LIFE_DOCUMENT_TYPE, ...VOL_LIFE_DOCUMENT_TYPE };
      case BENEFIT_CATEGORY.RETIREMENT.value:
        return RETIREMENT_DOC_TYPE;
      case TAX_ADVANTAGED_ACCOUNT:
        return TAX_ADVANTAGED_DOC_TYPE;
      default:
        return {};
    }
  };

  const getPlanTypeAsName = (planType: string) => {
    const planTypeName = planType.replace(/_/g, ' ');
    const tokens = planTypeName.split(' ');

    if (planType === RETIREMENT_DOC_TYPE.FOUR_O_ONE_K_ROLLOVER_FORM.value) {
      return '401(k) Rollover Form';
    }
    if (tokens.length === 1) {
      return planTypeName;
    } else {
      for (let i = 0; i < tokens.length; i++) {
        tokens[i] =
          tokens[i].charAt(0).toUpperCase() + tokens[i].toLowerCase().slice(1);
      }
      return tokens.join(' ');
    }
  };

  const orderDocuments = (docsOrder: string[], documents: any) => {
    const docObj = {} as any;

    docsOrder.forEach((docType) => {
      return Object.keys(documents)
        .filter((key) => key.includes(docType))
        .reduce((cur, key) => {
          return (docObj[key] = documents[key]);
        }, {});
    });
    return docObj;
  };

  const changePlanDocumentOrder = (documents: any, benefitKind: string) => {
    switch (benefitKind) {
      case BENEFIT_CATEGORY.MEDICAL.value:
        return orderDocuments(MEDICAL_DOCS_ORDER, documents);
      case BENEFIT_CATEGORY.DENTAL.value:
      case BENEFIT_CATEGORY.VISION.value:
        return orderDocuments(DV_DOCS_ORDER, documents);
      case BENEFIT_CATEGORY.RETIREMENT.value:
        return orderDocuments(RETIREMENT_DOCS_ORDER, documents);
      case TAX_ADVANTAGED_TYPE.HSA:
      case TAX_ADVANTAGED_TYPE.HRA:
      case TAX_ADVANTAGED_TYPE.FSA:
      case TAX_ADVANTAGED_TYPE.LIMITED_FSA:
      case TAX_ADVANTAGED_TYPE.DCAP_FSA:
      case TAX_ADVANTAGED_TYPE.COMMUTER_GENERIC:
        return orderDocuments(TAX_ADVANTAGED_DOCS_ORDER, documents);
      case LIFE_PLAN_TYPES.BASIC_ADD.value:
      case LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value:
      case LIFE_PLAN_TYPES.BASIC_LIFE.value:
        return orderDocuments(BASIC_LIFE_AND_ADD_DOCS_ORDER, documents);
      case LIFE_PLAN_TYPES.STD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value:
        return orderDocuments(STD_DOCS_ORDER, documents);
      case LIFE_PLAN_TYPES.LTD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value:
        return orderDocuments(LTD_DOCS_ORDER, documents);
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value:
      case VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value:
        return orderDocuments(VOL_LIFE_DOCS_ORDER, documents);
      default:
        return {};
    }
  };

  const viewDetails = (plan: any, benefitKind: string) => {
    let planDocument;
    if (isVolBenefitKind(benefitKind)) {
      planDocument = plan.documents[PLAN_SUMMARY];
    } else {
      planDocument = plan.documents[PLAN_DOCUMENT];
    }

    if (plan.webLink) {
      window.open(addHttp(plan.webLink), '_blank')?.focus();
    } else if (planDocument) {
      window
        .open(
          `${baseApi}/v1/plans/${plan.id}/documents?documentType=${
            isVolBenefitKind(benefitKind) ? PLAN_SUMMARY : PLAN_DOCUMENT
          }&benefitKind=${benefitKind}&isOpen=true`,
          '_blank'
        )
        ?.focus();
    }

    trackEvents({
      category: 'View Details Resources',
      action: `view_${benefitKind}_details_resources`,
      label: `View ${benefitKind} Details Resources`,
    });
  };

  const isWeblinkSupportedPlan =
    TELEHEALTH_BENEFITS_KIND.includes(resource.benefitKind) ||
    VOL_BENEFITS_KIND.includes(resource.benefitKind) ||
    resource.benefitKind === BENEFIT_CATEGORY.WELLBEING.value;

  return (
    <div className={styles.resourceCardWrapper}>
      <div className={styles.resourceCard}>
        {resource.plans.map((plan: any, index: number) => {
          return (
            <div className={styles.planColumn} key={index}>
              <EditableHotspot
                alwaysVisible={false}
                editText="Edit"
                editIcon={<img src={iconEdit} alt="edit" />}
                customModal={(visible: boolean, onClose: Function) => (
                  <PlanDocuments
                    visible={visible}
                    onClose={onClose}
                    benefitKind={
                      resource.benefitKind === TAX_ADVANTAGED_ACCOUNT ||
                      resource.type === LIFE
                        ? plan.benefitKind
                        : resource.benefitKind
                    }
                    planDocumentTypes={getDocumentTypes(
                      resource.benefitKind === TAX_ADVANTAGED_ACCOUNT
                        ? TAX_ADVANTAGED_ACCOUNT
                        : plan.benefitKind || resource.benefitKind
                    )}
                    plan={plan}
                    isDisabled={notificationFlag}
                  />
                )}
              >
                <>
                  <div
                    className={styles.planName}
                    title={plan.name || plan.planName}
                  >
                    {plan.name || plan.planName}
                  </div>
                  <div className={styles.planResourceWrapper}>
                    {!isWeblinkSupportedPlan &&
                      plan &&
                      plan.documents &&
                      Object.entries(
                        changePlanDocumentOrder(
                          plan.documents,
                          plan.benefitKind || resource.benefitKind
                        )
                      ).map((document, index) => {
                        return (
                          <div className={styles.planLinkWrapper} key={index}>
                            <a
                              href={`${baseApi}/v1/plans/${
                                plan.id
                              }/documents?documentType=${
                                document[0]
                              }&benefitKind=${
                                plan.benefitKind || resource.benefitKind
                              }&isOpen=${true}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.planDocLinks}
                            >
                              {`View ${getPlanTypeAsName(document[0])}`}
                            </a>
                          </div>
                        );
                      })}
                    {isWeblinkSupportedPlan && plan && (
                      <div
                        className={styles.viewResourceLink}
                        onClick={() =>
                          viewDetails(
                            plan,
                            resource.benefitKind === TAX_ADVANTAGED_ACCOUNT ||
                              resource.type === LIFE ||
                              resource.benefitKind ===
                                BENEFIT_CATEGORY.WELLBEING.value
                              ? plan.benefitKind
                              : resource.benefitKind
                          )
                        }
                      >
                        {plan.webLink || !isEmpty(plan.documents)
                          ? 'View Resource'
                          : ''}
                      </div>
                    )}
                    {plan &&
                      convertToOptions(plan.additionalDocuments).map(
                        (additionalDoc: Option, index: number) => {
                          const documentType = ALL_DOCUMENT_TYPE.includes(
                            additionalDoc.value
                          )
                            ? additionalDoc.value
                            : 'PLAN_ADDITIONAL_DOCUMENT';
                          return (
                            <div
                              className={styles.viewResourceLink}
                              key={index}
                            >
                              <a
                                href={`${baseApi}/v1/plans/${
                                  plan.id
                                }/documents?documentType=${documentType}&benefitKind=${
                                  plan.benefitKind || resource.benefitKind
                                }&isOpen=${true}&planDocumentName=${
                                  encodeURIComponent(additionalDoc.value) ||
                                  null
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.planDocLinks}
                              >
                                {additionalDoc.value
                                  ? additionalDoc.value
                                  : additionalDoc.label}
                              </a>
                            </div>
                          );
                        }
                      )}
                    {plan &&
                      convertToWebLinkType(plan.additionalWeblinks).map(
                        (additionalWeblink: WebLinkType, index: number) => {
                          return (
                            <div
                              className={styles.viewResourceLink}
                              key={index}
                              onClick={() =>
                                window
                                  .open(
                                    addHttp(additionalWeblink.weblink),
                                    '_blank'
                                  )
                                  ?.focus()
                              }
                            >
                              {additionalWeblink.planDocumentName
                                ? additionalWeblink.planDocumentName
                                : additionalWeblink.weblink}
                            </div>
                          );
                        }
                      )}
                  </div>
                </>
              </EditableHotspot>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ResourceCard;
