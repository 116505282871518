import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import Plan from 'model/Plan';
import {
  convertOptionListToDisplayResourceTypeList,
  convertToDisplayResourceType,
  convertToOptions,
} from 'utils/commonUtil';
import Option from 'model/Option';
import WebLinkType from 'model/WebLinkType';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { openDocument, openWebLink } from 'modules/benefitsWellness/util';
import MDVPlan from 'model/MDVPlan';
import { NO_NAME } from 'constants/commonConstants';
import OverflowPopover from 'components/PlanYearOverFlowPopover/OverflowPopover';

import DisplayResourceType from 'model/DisplayResourceType';
import { FILE } from 'modules/resources/constants';
import styles from './viewResourceModal.module.less';

interface ViewResourcesModalProps {
  plan: Plan | MDVPlan;
  isVisible: boolean;
  setIsVisible: Function;
  benefitKind: string;
}

const ViewResourcesModal: FC<ViewResourcesModalProps> = (
  props: ViewResourcesModalProps
) => {
  const { plan, isVisible, setIsVisible, benefitKind } = props;

  const [displayList, setDisplayList] = useState<DisplayResourceType[]>([]);

  useEffect(() => {
    if (plan) {
      const resourcesList: DisplayResourceType[] = [];
      if (plan.additionalDocuments || plan.documents) {
        const convertedList = convertToOptions(plan.documents).concat(
          convertToOptions(plan.additionalDocuments)
        );
        resourcesList.push(
          ...convertOptionListToDisplayResourceTypeList(convertedList)
        );
        setDisplayList((prevList: DisplayResourceType[]) => [
          ...prevList,
          ...convertOptionListToDisplayResourceTypeList(convertedList),
        ]);
      }
      if (plan.additionalWeblinks) {
        resourcesList.push(
          ...convertToDisplayResourceType(plan.additionalWeblinks, true)
        );
      }

      const sortedList = resourcesList.sort((firstValue, secondValue) => {
        const firstName = firstValue.name.startsWith(NO_NAME)
          ? firstValue.value instanceof File
            ? firstValue.value.name
            : firstValue.value
          : firstValue.name;
        const secondName = secondValue.name.startsWith(NO_NAME)
          ? secondValue.value instanceof File
            ? secondValue.value.name
            : secondValue.value
          : secondValue.name;
        return firstName.localeCompare(secondName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });

      setDisplayList(sortedList);
    }
  }, [plan]);

  const renderDisplayList = (resource: DisplayResourceType, index: number) => {
    if (resource.type === FILE) {
      return renderList({ value: resource.name, label: resource.value }, index);
    } else {
      return renderWebList(
        { weblink: resource.value, planDocumentName: resource.name },
        index
      );
    }
  };
  const renderList = (docType: Option, index: number) => {
    return (
      <div key={index}>
        <LinkButton
          onClick={() => openDocument(plan.id, docType.value, benefitKind)}
        >
          <OverflowPopover maxWidth="490px">
            {!docType.value.startsWith(NO_NAME) ? docType.value : docType.label}
          </OverflowPopover>
        </LinkButton>
      </div>
    );
  };
  const renderWebList = (webLink: WebLinkType, index: number) => {
    return (
      <div key={index}>
        <LinkButton onClick={() => openWebLink(webLink.weblink, benefitKind)}>
          <OverflowPopover maxWidth="490px">
            {webLink.planDocumentName == null ||
            webLink.planDocumentName === 'undefined' ||
            webLink.planDocumentName.startsWith(NO_NAME)
              ? webLink.weblink
              : webLink.planDocumentName}
          </OverflowPopover>
        </LinkButton>
      </div>
    );
  };

  return (
    <ConfirmationDialog
      width="550px"
      title="PLAN RESOURCES"
      visible={isVisible}
      footer={[
        <Button
          className={styles.doneButton}
          key="submit"
          type="primary"
          onClick={() => setIsVisible(false)}
        >
          Done
        </Button>,
      ]}
    >
      {displayList.map(renderDisplayList)}
    </ConfirmationDialog>
  );
};

export default ViewResourcesModal;
