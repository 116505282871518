import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { BenefitData } from 'modules/auth/types/types';
import WebLinkType from 'model/WebLinkType';
import Option from 'model/Option';
import {
  FILE,
  FORMAT_VALIDATE,
  INVALID_WEBLINK,
  SIZE_VALIDATE,
  WEBLINK,
} from 'modules/resources/constants';
import DisplayResourceType from 'model/DisplayResourceType';
import FileType from 'model/FileType';

const jp = require('jsonpath');
type InputObject = { [key: string]: string };

export const validateHex = (color: string) => {
  const regex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i;
  return regex.test(color);
};

export const rgbaToHex = (rgba: any) => {
  rgba = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return rgba && rgba.length === 4
    ? '#' +
        ('0' + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[3], 10).toString(16)).slice(-2)
    : '';
};

export const convertToDisplayResourceType = (
  input: InputObject,
  isWebLinkType: boolean
): DisplayResourceType[] => {
  if (!input) {
    return [];
  }
  const result: DisplayResourceType[] = [];
  for (const [key, value] of Object.entries(input)) {
    result.push({
      value: value,
      name: key,
      type: isWebLinkType ? WEBLINK : FILE,
    });
  }
  return result;
};

// Function to convert a Option list to DisplayResourceType list
export const convertOptionListToDisplayResourceTypeList = (
  options: Option[]
): DisplayResourceType[] => {
  return options.map((option) => ({
    value: option.label,
    name: option.value,
    type: 'FILE',
  }));
};

// Function to convert a WebLinkType list to DisplayResourceType list
export const convertWebLinkTypeListToDisplayResourceTypeList = (
  webLinks: WebLinkType[]
): DisplayResourceType[] => {
  return webLinks?.map((webLink) => ({
    value: webLink.weblink,
    name: webLink.planDocumentName,
    type: 'WEBLINK',
  }));
};

// Function to convert a WebLinkType list to DisplayResourceType list
export const convertSelectedListToDisplayResourceTypeList = (
  selectedList: FileType[]
): DisplayResourceType[] => {
  return selectedList.map((selectedDoc) => ({
    value: selectedDoc.file,
    name: selectedDoc.planDocumentName,
    type: 'FILE',
  }));
};

export const convertToOptions = (input: InputObject): Option[] => {
  if (!input) {
    return [];
  }
  const result: Option[] = [];
  for (const [key, value] of Object.entries(input)) {
    result.push({ value: key, label: value });
  }
  return result;
};
export const convertToWebLinkType = (input: InputObject): WebLinkType[] => {
  if (!input) {
    return [];
  }
  const result: WebLinkType[] = [];
  for (const [key, value] of Object.entries(input)) {
    result.push({ weblink: value, planDocumentName: key });
  }
  return result;
};

export const convertArrayToOptions = (enums: Array<number | string>) => {
  return enums.map((item) => ({
    value: item,
    label: item,
  }));
};

export const convertEnumToOptions = (enums: object) => {
  return Object.values(enums).map((configs: { value: any; label: string }) => ({
    value: configs.value,
    label: configs.label,
  }));
};

export const getValidationMessage = (validateSetting: string): string => {
  switch (validateSetting) {
    case FORMAT_VALIDATE:
      return 'This file type is not supported. You can only upload pdf file';
    case SIZE_VALIDATE:
      return 'Maximum size allowed for this upload is 100 MB.';
    case INVALID_WEBLINK:
      return 'Invalid weblink';
    default:
      return '';
  }
};

export const removeFileExtension = (filename: string) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return filename;
  }
  return filename.substring(0, lastDotIndex);
};

export const currencyFormatter = (number: any) => {
  let num = number;
  num =
    num && !isNaN(num)
      ? '$' +
        parseFloat(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '$0';

  const decimalsOfNum = num.split('.');
  if (decimalsOfNum[1] === '00') num = decimalsOfNum[0];

  return num;
};

export const currencyFormatterForSignedNumbers = (
  value: any,
  format = 'en-US',
  currency = 'USD'
) => {
  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });
  return isFinite(value) ? formatter.format(value) : '';
};

export const currencyFormatterForSignedNumbersWithoutDecimalPoints = (
  value: any,
  format = 'en-US',
  currency = 'USD'
) => {
  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
  });
  return isFinite(value) ? formatter.format(value) : '';
};

export function getFrequencies(frequency: string) {
  switch (frequency) {
    case 'MONTHLY':
      return '12 months';
    case 'SEMI_MONTHLY':
      return '24';
    case 'BI_WEEKLY':
      return '26';
    default:
      return frequency;
  }
}

const networkFieldVariance = {
  InNetwork: {
    camelCase: 'inNetwork',
    properCase: 'InNetwork',
  },
  OutOfNetwork: {
    camelCase: 'outOfNetwork',
    properCase: 'OutOfNetwork',
  },
} as any;

export const executeJsonPathQuery = (
  query: any,
  cardData: any,
  network: any,
  rate: any
) => {
  if (query) {
    return jp.query(
      cardData,
      query
        .replace(':rate4Tier', rate.fourTierValue)
        .replace(':rateNTier', rate.nTierValue)
        .replace(':networkCamelCase', networkFieldVariance[network].camelCase)
        .replace(':networkProperCase', networkFieldVariance[network].properCase)
    );
  } else {
    return '';
  }
};

const NUMBER_RANGES = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

export const planRecommenderStatusErrorMessages = [
  {
    title: 'ANY_MEDICAL_PLAN_WITH_N_TIER_OR_AGE_BAND',
    code: 1,
    message:
      'Plan Recommender cannot be enabled because plans with N-Tier or Age Banded Rates have been added.',
  },
  {
    title: 'MEDICAL_RATE_OR_CONTRIBUTIONS_DISABLED',
    code: 2,
    message:
      'Plan recommender cannot be enabled because contributions are disabled for this guide.',
  },
  {
    title: 'MISSING_MEDICAL_PLAN_INFORMATION',
    code: 3,
    message:
      'Plan recommender cannot be enabled because there is missing plan data that is used to provide recommendations.',
  },
];

export const BenifitGuidEditorTitles = [
  'Basic Guide Info',
  'Home Screen',
  'New Hire Checklist',
  'How Insurance Works',
  'Dates & Eligibility',
  'Medical',
  'Dental',
  'Vision',
  'Holidays & Time Off',
];

export const planRecommenderStatusMissingData = [
  {
    title: 'PLAN_TYPE',
    code: 1,
    message: 'Plan Type (PPO, EPO, HMO)',
  },
  {
    title: 'EMPLOYEE_CONTRIBUTION',
    code: 2,
    message: 'Employee Contributions',
  },
  {
    title: 'DEDUCTIBLE',
    code: 3,
    message: 'Deductible',
  },
  {
    title: 'OOP_MAXIMUM',
    code: 4,
    message: 'OOP Maximum',
  },
  {
    title: 'PCP_VISIT',
    code: 5,
    message: 'PCP Visit',
  },
  {
    title: 'SPECIALIST_VISIT',
    code: 6,
    message: 'Specialist Visit',
  },
  {
    title: 'IN_PATIENT',
    code: 7,
    message: 'Inpatient',
  },
  {
    title: 'OUT_PATIENT',
    code: 8,
    message: 'Outpatient',
  },
  {
    title: 'RX_TIER',
    code: 9,
    message: 'Rx Tier [#]',
  },
  {
    title: 'RX_TIER_1',
    code: 10,
    message: 'Tier 1 Retail Rx',
  },
  {
    title: 'RX_TIER_2',
    code: 11,
    message: 'Tier 2 Retail Rx',
  },
  {
    title: 'RX_TIER_3',
    code: 12,
    message: 'Tier 3 Retail Rx',
  },
  {
    title: 'RX_TIER_4',
    code: 13,
    message: 'Tier 4 Retail Rx',
  },
];

export function formatNumber(n: number) {
  for (let i = 0; i < NUMBER_RANGES.length; i++) {
    if (n >= NUMBER_RANGES[i].divider) {
      return (
        (n / NUMBER_RANGES[i].divider).toString() + NUMBER_RANGES[i].suffix
      );
    }
  }
  return n.toString();
}

export const isVolBenefitKind = (benefitKind: string) => {
  return (
    benefitKind === 'ACCIDENT' ||
    benefitKind === 'CRITICAL_ILLNESS' ||
    benefitKind === 'HOSPITAL' ||
    benefitKind === 'CUSTOM_VOLUNTARY_BENEFIT'
  );
};

export const addOrReplace = (arr: any[], newObj: any, key: string) => {
  return [...arr.filter((obj) => obj[key] !== newObj[key]), { ...newObj }];
};

export const formatEffectiveDates = (
  startDate: string,
  endDate: string
): { startDate: string; endDate: string } => {
  if (endDate && startDate) {
    const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY');
    const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');
    return { startDate: formattedStartDate, endDate: formattedEndDate };
  } else {
    return { startDate: '-', endDate: '-' };
  }
};

export const extractBenefitSections = (data: {
  [key: string]: BenefitData | undefined;
}): { [key: string]: any[] } => {
  const result: { [key: string]: any[] } = {
    MEDICAL: [],
    DENTAL: [],
    VISION: [],
  };

  const sectionsToExtract: Array<keyof typeof result> = [
    'medical',
    'dental',
    'vision',
  ];

  sectionsToExtract.forEach((sectionName) => {
    if (data && data[sectionName] && data[sectionName]!.plans) {
      result[sectionName.toString().toUpperCase()] = data[sectionName]!.plans;
    }
  });
  return result;
};

export const ID_CARD_PAGE_ACTIONS_OPTIONS = {
  // TODO: Share ID will be added later
  // SHARE_ID: {
  //   value: 'SHARE_ID',
  //   label: 'Share ID',
  // },

  PRINT_ID: { value: 'PRINT_ID', label: 'Print ID' },
  SHOW_FRONT: { value: 'SHOW_FRONT', label: 'Show Front of ID Card' },
  SHOW_BACK: {
    value: 'SHOW_BACK',
    label: 'Show Back of ID Card',
  },
  UPDATE_ID: { value: 'UPDATE_ID', label: 'Update My Plans' },
  LOGOUT: { value: 'LOGOUT', label: 'Log Out' },
} as const;

export const getPlanCoverFooterHeight = (tierCount: number) => {
  switch (tierCount) {
    case 2:
      return 428;
    case 3:
      return 456;
    case 4:
      return 484;
    case 5:
      return 526;
    case 6:
      return 554;
    case 7:
      return 582;
    case 8:
      return 610;
    default:
      return 400;
  }
};

export const getPlanCoverFooterHeightMobile = (tierCount: number) => {
  const defaultValue: number = 700;
  switch (tierCount) {
    case 2:
      return defaultValue + 58;
    case 3:
      return defaultValue + 58 * 2;
    case 4:
      return defaultValue + 58 * 3;
    case 5:
      return defaultValue + 58 * 4;
    case 6:
      return defaultValue + 58 * 5;
    case 7:
      return defaultValue + 58 * 6;
    case 8:
      return defaultValue + 58 * 7;
    default:
      return defaultValue;
  }
};

export const getPlanBreakdownMarginTop = (tierCount: number) => {
  switch (tierCount) {
    case 5:
      return 20;
    case 6:
      return -8;
    case 7:
      return -36;
    case 8:
      return -64;
    default:
      return 48;
  }
};

export const getMaxRxCostCount = (plans: any) => {
  let max = 0;
  plans.forEach((plan: any) => {
    const { rxCosts } = plan;
    const tierCount = Object.keys(rxCosts?.inNetwork).length;
    if (tierCount > max) {
      max = tierCount;
    }
  });
  return max;
};

export const sortAlphabetic = (dataArray: any[], sortingElement: string) => {
  return dataArray.sort((a: any, b: any) => {
    if (a[sortingElement] < b[sortingElement]) {
      return -1;
    }
    if (a[sortingElement] > b[sortingElement]) {
      return 1;
    }
    return 0;
  });
};

/*
 * this util method has been added as a temporary fix for the issue with fetching plans when benefit classes have commas
 */
export const modifyAndEncodeBenefitClassNamesToHandleComma = (
  benefitGroups: string[]
) => {
  if (isEmpty(benefitGroups)) {
    return benefitGroups;
  }
  return benefitGroups.map((group) => {
    if (group.includes(',')) {
      console.log('replace');
      return group.replaceAll(',', '@@@');
    }
    return group;
  });
};
