/* eslint-disable no-unused-vars */
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import { Button } from 'antd';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import { joinClasses } from 'utils/stylesUtil';
import {
  findFrequencyByBenefitKind,
  getAdditionalServices,
  getConfiguredBenefitCodeList,
  showRxRow,
} from 'modules/benefitsWellness/util';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import useBenguide from 'hooks/benguide';
import MDVPlan from 'model/MDVPlan';
import meta from 'modules/benefitsWellness/data/metadata';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import MDVMetaGroup from 'model/MDVMetaGroup';
import MDVMetaService from 'model/MDVMetaService';
import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import CustomRowModal from 'components/CustomRowModal/CustomRowModal';
import FrequencyConfiguration from 'modules/benefitsWellness/components/FrequencyConfiguration/FrequencyConfiguration';
import iconEdit from 'images/icon-edit.svg';
import {
  DENTAL_PLAN_CHANNEL,
  MEDICAL_PLAN_CHANNEL,
  VISION_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import {
  DENTAL_ADVANCED_PROFILE_PARAMETERS,
  VISION_ADVANCED_PROFILE_PARAMETERS,
  mdvServicesToolTipCustomResponse,
  TOOLTIP_CONST,
} from 'constants/commonConstants';
import { checkBenefitGuidesRates } from 'modules/benefitsWellness/slices/benefitWellnessSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FREQUENCIES } from 'modules/benefitsWellness/constants/BenefitConstants';

import OverflowPopover from 'components/PlanYearOverFlowPopover/OverflowPopover';
import styles from './mdvCardTitles.module.less';

type MDVCardTitlesProps = {
  planType: MDVTypes;
  onUpdatePlanList: Function;
  addedPlanList: MDVPlan[];
  reloadPlans: Function;
  allPlans: MDVPlan[];
  allowHeaderTitle?: boolean;
  showAlert: Function;
  isLoading: boolean;
  detailsHeight?: number;
  isContributionsViewOpen?: boolean;
  onCloseContributions?: Function;
};

const MDVCardTitles: FC<MDVCardTitlesProps> = (props: MDVCardTitlesProps) => {
  const {
    planType,
    onUpdatePlanList,
    addedPlanList,
    reloadPlans,
    allPlans,
    allowHeaderTitle = false,
    showAlert,
    isLoading,
    detailsHeight,
    isContributionsViewOpen = false,
    onCloseContributions = () => {},
  } = props;
  const currentBenguide = useBenguide();
  const dispatch = useAppDispatch();
  const { rateValidations } = useAppSelector((state) => state.benefitWellness);
  const isEditMode = currentBenguide.isEditMode;

  const [dataGroups, setDataGroups] = useState<MDVMetaGroup[]>([]);
  const [additionalServices, setAdditionalServices] = useState<
    MDVMetaService[]
  >([]);
  const [contributionModalClose, setContributionModalClose] =
    useState<boolean>(false);
  const { selectedNetwork } = useAppSelector((state) => state.benefitWellness);

  useEffect(() => {
    if (planType) {
      const fieldGroups = meta.benguideMetaData.planFieldGroupings.find(
        (planFieldGroup) => planFieldGroup.planType === planType.toLowerCase()
      );

      const definedBenefitCodes = getConfiguredBenefitCodeList(
        fieldGroups?.hotspots || []
      );
      setAdditionalServices(
        getAdditionalServices(addedPlanList, definedBenefitCodes)
      );
      setDataGroups(fieldGroups?.hotspots || []);
      dispatch(
        checkBenefitGuidesRates(
          currentBenguide.masterId,
          planType.toUpperCase()
        )
      );
    }
  }, [planType, addedPlanList, dispatch, currentBenguide.masterId]);

  useEffect(() => {
    onCloseContributions(contributionModalClose);
  }, [contributionModalClose, onCloseContributions]);

  const getFrequencyEditor = (isModelOpen: boolean, closeModal: any) => {
    return (
      <FrequencyConfiguration
        isOpenModal={isModelOpen || isContributionsViewOpen}
        closeModal={closeModal}
        benefitKind={planType.toUpperCase()}
        onCloseContributions={setContributionModalClose}
      />
    );
  };

  const getCustomRowEditor = (isModelOpen: boolean, closeModal: any) => {
    return (
      <CustomRowModal
        isOpenModal={isModelOpen}
        closeModal={closeModal}
        benguideId={currentBenguide.masterId}
        planList={addedPlanList}
      />
    );
  };

  const getMDVChannel = () => {
    if (planType === MDVTypes.MEDICAL) {
      return MEDICAL_PLAN_CHANNEL;
    } else if (planType === MDVTypes.DENTAL) {
      return DENTAL_PLAN_CHANNEL;
    } else {
      return VISION_PLAN_CHANNEL;
    }
  };

  const nonExistCustomServices = {} as any;
  const nonExistRxCosts = {} as any;
  const nonExistDeductibleIndividual = {} as any;
  const showIfExistServiceList = dataGroups
    .filter((titleGroup) => !!titleGroup.isCustomizedServices)
    .map((titleGroup) =>
      titleGroup.elements.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  const showIfExistRxCostsList = dataGroups
    .filter((titleGroup) => !!titleGroup.isCustomizedRX)
    .map((titleGroup) =>
      titleGroup.elements.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  const showIfExistDeductibleIndividualList = dataGroups
    ?.filter((item) => item?.isCustomizedDeductible)
    ?.map((item) =>
      item?.elements?.filter((title: any) => title.showTitleIfExists)
    )[0] as MDVMetaService[];

  addedPlanList.forEach((plan) => {
    showIfExistServiceList &&
      showIfExistServiceList.forEach((service) => {
        if (
          plan.customServices &&
          !plan.customServices.find(
            (customService) =>
              customService.benefitCode &&
              customService.benefitCode.code === service.serviceBenefitCode
          ) &&
          nonExistCustomServices
        ) {
          nonExistCustomServices[service.serviceBenefitCode] =
            nonExistCustomServices[service.serviceBenefitCode]
              ? nonExistCustomServices[service.serviceBenefitCode] + 1
              : 1;
        }
      });

    showIfExistRxCostsList &&
      showIfExistRxCostsList.forEach((rxCost) => {
        if (
          (rxCost.fieldName === 'rxCost' &&
            rxCost.rxCode &&
            plan.rxCosts &&
            !(
              plan.rxCosts['inNetwork'].hasOwnProperty(rxCost.rxCode) ||
              plan.rxCosts['outOfNetwork'].hasOwnProperty(rxCost.rxCode)
            )) ||
          (rxCost.fieldName === 'mailRxCost' &&
            rxCost.rxCode &&
            plan.mailOrderRxCosts &&
            !(
              plan.mailOrderRxCosts['inNetwork'].hasOwnProperty(
                rxCost.rxCode
              ) ||
              plan.mailOrderRxCosts['outOfNetwork'].hasOwnProperty(
                rxCost.rxCode
              )
            ) &&
            nonExistRxCosts)
        ) {
          nonExistRxCosts[rxCost.fieldName + rxCost.rxCode] = nonExistRxCosts[
            rxCost.fieldName + rxCost.rxCode
          ]
            ? nonExistRxCosts[rxCost.fieldName + rxCost.rxCode] + 1
            : 1;
        }
      });

    showIfExistDeductibleIndividualList?.forEach((deductibleIndividual) => {
      if (
        deductibleIndividual.fieldName === 'indWithinFamilyDeductible' &&
        (plan?.deductibles?.hasOwnProperty(
          'individualWithinFamilyInNetworkCost'
        ) ||
          plan?.deductibles?.hasOwnProperty(
            'individualWithinFamilyOutOfNetworkCost'
          ))
      ) {
        nonExistDeductibleIndividual[deductibleIndividual.fieldName] =
          nonExistDeductibleIndividual[deductibleIndividual.fieldName]
            ? nonExistDeductibleIndividual[deductibleIndividual.fieldName] + 1
            : 1;
      }
      if (
        deductibleIndividual.fieldName === 'indWithinFamilyOOPMax' &&
        (plan?.outOfPocket?.hasOwnProperty(
          'individualWithinFamilyInNetworkCost'
        ) ||
          plan?.outOfPocket?.hasOwnProperty(
            'individualWithinFamilyOutOfNetworkCost'
          ))
      ) {
        nonExistDeductibleIndividual[deductibleIndividual.fieldName] =
          nonExistDeductibleIndividual[deductibleIndividual?.fieldName]
            ? nonExistDeductibleIndividual[deductibleIndividual?.fieldName] + 1
            : 1;
      }
    });
  });

  const showAdditionalServices = () => {
    const rows = [] as any[];
    additionalServices
      .filter((service) => {
        if (planType === MDVTypes.DENTAL && !currentBenguide.isAdvanceProfile) {
          return !DENTAL_ADVANCED_PROFILE_PARAMETERS.includes(
            service.fieldValue
          );
        }
        if (planType === MDVTypes.VISION && !currentBenguide.isAdvanceProfile) {
          return !VISION_ADVANCED_PROFILE_PARAMETERS.includes(
            service.fieldValue
          );
        }
        return true;
      })
      .forEach((service) => {
        const customToolTip = mdvServicesToolTipCustomResponse.find(
          (CustomResponseServices) =>
            CustomResponseServices.label === service.fieldValue
        );

        return rows.push(
          <div key={service.fieldValue} className={styles.titleItem}>
            <div className={styles.title}>
              {isEditMode ? (
                <span className={styles.titleValue}>
                  <OverflowPopover placement="right">
                    {service.fieldValue}
                  </OverflowPopover>
                </span>
              ) : (
                <TooltipPopover
                  content={
                    customToolTip?.label === service.fieldValue
                      ? customToolTip.text
                      : service.fieldValue
                  }
                  placement="right"
                >
                  <span className={styles.titleValue}>
                    {service.fieldValue}
                  </span>
                </TooltipPopover>
              )}
            </div>
          </div>
        );
      });
    return rows;
  };

  const excludeIfCustomServicesUnavailable = (title: MDVMetaService) => {
    const { excludeIfUnavailable } = title;
    let isExcluded = false;
    excludeIfUnavailable &&
      excludeIfUnavailable.forEach((serviceCode: number) => {
        if (serviceCode === 0 && isEditMode) {
          return (isExcluded = additionalServices.length > 0);
        }
        if (
          nonExistCustomServices &&
          nonExistCustomServices.hasOwnProperty(
            nonExistCustomServices[serviceCode]
          ) &&
          addedPlanList.length !== nonExistCustomServices[serviceCode]
        ) {
          return (isExcluded = true);
        }
      });
    return isExcluded;
  };

  const attachedServices = addedPlanList
    .reduce((previousValue: any, customService: any): any => {
      return [...previousValue, ...customService.customServices];
    }, [])
    .map((service: any) => service?.benefitCode?.code);

  return (
    <div className={styles.mdvCardTitlesWrapper}>
      {isEditMode && (
        <div className={styles.addOrRemovePlansWrapper}>
          <EditableHotspot
            alwaysVisible={true}
            customModal={(visible: boolean, onClose: Function) => (
              <AddOrRemovePlans
                visible={visible}
                onClose={onClose}
                title={`${planType}`}
                onSave={onUpdatePlanList}
                addedPlanList={addedPlanList}
                allPlans={allPlans}
                reloadPlans={reloadPlans}
                channel={getMDVChannel()}
                showAlert={showAlert}
                isLoading={isLoading}
              />
            )}
          >
            <Button>+ Add or Remove Plans</Button>
          </EditableHotspot>
        </div>
      )}

      <div className={styles.titleContainerWrapper}>
        <div
          className={joinClasses([
            styles.titleContainer,
            styles.titleContainerMargin,
          ])}
        >
          {dataGroups.map((titleGroup, titleGroupIndex) => {
            return titleGroup.elements.map((title: any, titleIndex: number) => {
              if (showRxRow(title, addedPlanList, selectedNetwork)) {
                if (title.fieldValue?.length > 0) {
                  if (
                    (titleGroupIndex === 0 || titleGroupIndex === 1) &&
                    allowHeaderTitle
                  ) {
                    if (title.fieldName === 'monthlyCost') {
                      const contribFreq = findFrequencyByBenefitKind(
                        currentBenguide.contributionFrequencies,
                        planType.toUpperCase()
                      );
                      const { enabled } = contribFreq;
                      if (
                        !isEditMode &&
                        (!enabled || rateValidations.hasMismatchContributions)
                      ) {
                        return <></>;
                      }
                      return (
                        <div className={styles.tittleTopWrapper}>
                          <EditableHotspot
                            alwaysVisible={true}
                            editIcon={<img src={iconEdit} alt="edit" />}
                            customModal={getFrequencyEditor}
                            key={titleGroup.event}
                          >
                            <div
                              className={`${joinClasses([
                                styles.titleTop,
                                styles.monthlyCost,
                                planType?.toLowerCase(),
                              ])} ${
                                isEditMode &&
                                (!enabled ||
                                  rateValidations.hasMismatchContributions)
                                  ? styles.monthlyCostDisabled
                                  : ''
                              }`}
                            >
                              <span className={styles.titleValue}>
                                {`Your ${
                                  FREQUENCIES[contribFreq.frequency]?.label ??
                                  ''
                                } Cost`}
                              </span>
                            </div>
                          </EditableHotspot>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={titleIndex}
                        className={joinClasses([
                          styles.titleItem,
                          styles.planNameContainer,
                          planType === MDVTypes.MEDICAL
                            ? styles.medicalPlanName
                            : '',
                        ])}
                        style={
                          detailsHeight ? { height: detailsHeight + 4 } : {}
                        }
                      >
                        <div
                          className={
                            title.fieldValue === 'Plan Name'
                              ? joinClasses([styles.titleTop, styles.planName])
                              : joinClasses([
                                  styles.titleTop,
                                  styles.monthlyCost,
                                  planType === MDVTypes.MEDICAL
                                    ? styles.medicalCost
                                    : '',
                                ])
                          }
                        >
                          <span className={styles.titleValue}>
                            {title.fieldValue}
                          </span>
                        </div>
                      </div>
                    );
                  }
                  if (title.fieldName === 'customFields') {
                    // Use a Set to store unique field names
                    const uniqueCustomFields: string[] = [];

                    if (addedPlanList?.length > 0) {
                      addedPlanList?.[0]?.customFields?.forEach(
                        (customField) => {
                          uniqueCustomFields.push(customField.fieldName);
                        }
                      );
                    }

                    return (
                      <div key={titleIndex}>
                        {uniqueCustomFields?.map(
                          (uniqueField, uniqueFieldIndex) => (
                            <div
                              className={styles.title}
                              key={uniqueFieldIndex}
                            >
                              <span className={styles.titleValue}>
                                <OverflowPopover
                                  popoverContent={uniqueField}
                                  maxWidth={'98%'}
                                  placement="right"
                                >
                                  {uniqueField}
                                </OverflowPopover>
                              </span>
                            </div>
                          )
                        )}
                        {isEditMode && (
                          <div className={styles.tittleTopWrapper}>
                            <EditableHotspot
                              alwaysVisible={true}
                              customModal={getCustomRowEditor}
                            >
                              <div
                                className={joinClasses([
                                  styles.titleTop,
                                  styles.titleSeperator,
                                  planType?.toLowerCase(),
                                ])}
                              >
                                <span className={styles.titleValue}>
                                  + Manage Custom Rows
                                </span>
                              </div>
                            </EditableHotspot>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    if (
                      (title?.fieldName === 'indWithinFamilyDeductible' ||
                        title?.fieldName === 'indWithinFamilyOOPMax') &&
                      !nonExistDeductibleIndividual?.hasOwnProperty(
                        title?.fieldName
                      )
                    ) {
                      return null;
                    }
                    if (
                      (!title.serviceBenefitCode &&
                        !title.rxCode &&
                        !title.additionalServices) ||
                      (title.serviceBenefitCode &&
                        (!nonExistCustomServices.hasOwnProperty(
                          title.serviceBenefitCode
                        ) ||
                          addedPlanList.length !==
                            nonExistCustomServices[
                              title.serviceBenefitCode
                            ])) ||
                      (title.rxCode &&
                        (isEditMode ||
                          !nonExistRxCosts.hasOwnProperty(
                            title.fieldName + title.rxCode
                          ) ||
                          addedPlanList.length !==
                            nonExistRxCosts[title.fieldName + title.rxCode]))
                    ) {
                      let tooltipContent;
                      if (typeof title.fieldValue === 'string') {
                        tooltipContent = TOOLTIP_CONST[title.fieldValue];
                      }
                      return (
                        <div
                          key={title.fieldValue}
                          className={styles.titleItem}
                        >
                          <div className={styles.title}>
                            {isEditMode ||
                            isEmpty(title.fieldDescription) ||
                            isNullOrUndefined(title.fieldDescription) ? (
                              <div className={styles.titleValue}>
                                <div>
                                  <OverflowPopover placement="right">
                                    {title.fieldValue}
                                  </OverflowPopover>
                                </div>
                                <div>
                                  {tooltipContent && (
                                    <PlanyearPopover
                                      content={
                                        <div>{tooltipContent.subtitle}</div>
                                      }
                                      placement="right"
                                    >
                                      <QuestionCircleOutlined
                                        className={styles.flexicon}
                                      />
                                    </PlanyearPopover>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className={styles.titleValue}>
                                {Object.keys(TOOLTIP_CONST).includes(
                                  title.fieldValue
                                ) ? (
                                  <div>{title.fieldValue}</div>
                                ) : (
                                  <TooltipPopover
                                    content={title.fieldDescription}
                                    placement="right"
                                  >
                                    <div>{title.fieldValue}</div>
                                  </TooltipPopover>
                                )}
                                <div>
                                  {tooltipContent && (
                                    <PlanyearPopover
                                      content={
                                        <div>{tooltipContent.subtitle}</div>
                                      }
                                      placement="right"
                                    >
                                      <QuestionCircleOutlined
                                        className={styles.flexicon}
                                      />
                                    </PlanyearPopover>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                }
                if (title.additionalServices && additionalServices.length > 0) {
                  return showAdditionalServices();
                }

                if (title.fieldName === 'spaceField') {
                  if (title.includeIfServiceAvailable) {
                    const serviceAvailable = attachedServices.some((r: any) =>
                      title.includeIfServiceAvailable.includes(r)
                    );
                    return serviceAvailable ? (
                      <div className={styles.titleSeperator}></div>
                    ) : null;
                  } else if (
                    title?.isCustomField &&
                    isEditMode &&
                    addedPlanList?.[0]?.customFields?.length === 0
                  ) {
                    return <div className={styles.titleSeperator}></div>;
                  } else if (title.isCustomField && isEditMode) {
                    return null;
                  }
                }

                if (isEditMode && title.isRx) {
                  return <></>;
                }

                if (
                  !title.additionalServices &&
                  (!title.excludeIfUnavailable ||
                    (title.excludeIfUnavailable &&
                      excludeIfCustomServicesUnavailable(title)))
                )
                  return (
                    <div key={titleIndex} className={styles.titleSeperator} />
                  );
                return null;
              }
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default MDVCardTitles;
