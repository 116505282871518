import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Col, Form, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import Option from 'model/Option';
import Plan from 'model/Plan';
import FileType from 'model/FileType';
import WebLinkType from 'model/WebLinkType';
import AlertMessage, { AlertInfo } from 'components/AlertMessage/AlertMessage';

import {
  convertOptionListToDisplayResourceTypeList,
  convertSelectedListToDisplayResourceTypeList,
  convertToOptions,
  convertToWebLinkType,
  convertWebLinkTypeListToDisplayResourceTypeList,
  getValidationMessage,
} from 'utils/commonUtil';
import { ReactComponent as IconAddDocument } from 'images/icons/icon-add.svg';
import { ReactComponent as IconRemoveDocument } from 'images/icons/icon-remove-red.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  getDocumentNameWithHighestNumber,
  incrementNumberInFileName,
  isValidWeblink,
} from 'utils/fileUtil';
import {
  FILE,
  INVALID_WEBLINK,
  PLAN_ADDITIONAL_DOCUMENT,
  WEBLINK,
  removeResourceWarning,
} from 'modules/resources/constants';
import PlanResources from 'modules/benefitsWellness/components/PlanResources/PlanResources';
import { NO_NAME } from 'constants/commonConstants';
import EditDocType from 'model/EditDocType';
import DisplayResourceType from 'model/DisplayResourceType';
import styles from './AdditionalPlanResource.module.less';

type AdditionalPlanResourcesProps = {
  plan?: Plan;
  benefitKind?: string;
  isCloseConfirmed: boolean;
  selectedFileList: FileType[];
  setSelectedFileList: Function;
  selectedWebLinkList: WebLinkType[];
  setSelectedWebLinkList: Function;
  setIsDocRemoved: Function;
  editedList?: EditDocType[];
  setEditedList?: Function;
};

const formInitialValues = {
  planDocumentName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  weblink: '',
  fileName: '',
  groupId: '',
};

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';

const AdditionalPlanResources = forwardRef(
  (props: AdditionalPlanResourcesProps, ref) => {
    const {
      plan,
      benefitKind,
      isCloseConfirmed,
      selectedFileList,
      setSelectedFileList,
      selectedWebLinkList,
      setSelectedWebLinkList,
      setIsDocRemoved,
      editedList,
      setEditedList,
    } = props;

    const [isAddNewDocumentFormOpened, setIsAddNewDocumentOpened] =
      useState<boolean>(false);
    const [displayList, setDisplayList] = useState<DisplayResourceType[]>([]);
    const [
      temporaryAdditionalPlanDocuments,
      setTemporaryAdditionalPlanDocuments,
    ] = useState<FileType[]>([]);
    const [additionalPlanDocumentTypes, setAdditionalPlanDocumentTypes] =
      useState<Option[]>([]);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [file, setFile] = useState<any | null>(null);
    const [docs, setDocs] = useState<{
      [key: string]: string;
    }>({});
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(formInitialValues);
    const [additionalResourceType, setAdditionalResourceType] =
      useState<string>('');
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });
    const [visibleConfirmation, setConfirmationPopupVisible] = useState<{
      visible: boolean;
      docType?: Option | undefined;
      webLink?: WebLinkType | undefined;
    } | null>(null);
    const [removedDocuments, setRemovedDocuments] = useState<string[]>([]);
    const [removedWebLinks, setRemovedWebLinks] = useState<string[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [invalidField, setInvalidField] = useState<InvalidField>('');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [onEdit, setOnEdit] = useState<string>();
    const [editType, setEditType] = useState<string>();
    const [allNameList, setAllNameList] = useState<string[]>([]);

    useEffect(() => {
      const clonedList = cloneDeep(displayList);
      clonedList.sort((firstValue, secondValue) => {
        const firstName = firstValue.name.startsWith(NO_NAME)
          ? firstValue.value instanceof File
            ? firstValue.value.name
            : firstValue.value
          : firstValue.name;
        const secondName = secondValue.name.startsWith(NO_NAME)
          ? secondValue.value instanceof File
            ? secondValue.value.name
            : secondValue.value
          : secondValue.name;
        return firstName.localeCompare(secondName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      setDisplayList(clonedList);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFileList, additionalPlanDocumentTypes, selectedWebLinkList]);

    useEffect(() => {
      const list: FileType[] = selectedFileList?.filter(
        (fileType) =>
          fileType.docType === PLAN_ADDITIONAL_DOCUMENT &&
          !removedDocuments?.includes(fileType?.planDocumentName)
      );

      setTemporaryAdditionalPlanDocuments(list);
      if (plan) {
        setDisplayList([]);
        setDocs({
          ...plan.documents,
          ...plan.additionalDocuments,
        });
        if (plan?.additionalDocuments) {
          const convertedList: Option[] = convertToOptions(
            plan?.additionalDocuments
          )?.filter((document) => !removedDocuments.includes(document.value));
          setDisplayList((prevList: DisplayResourceType[]) => [
            ...prevList,
            ...convertOptionListToDisplayResourceTypeList(convertedList),
          ]);
          setAdditionalPlanDocumentTypes(convertedList);
          setAllNameList((prevList) => [
            ...prevList,
            ...convertedList
              .map((document) => document.value)
              .filter((name) => !removedDocuments.includes(name)),
          ]);
        }
        if (plan?.additionalWeblinks) {
          const convertedList = convertToWebLinkType(
            plan?.additionalWeblinks
          )?.filter(
            (weblink) => !removedWebLinks.includes(weblink.planDocumentName)
          );
          setSelectedWebLinkList(convertedList);
          console.log('WEB : ', convertedList);
          setDisplayList((prevList: DisplayResourceType[]) => [
            ...prevList,
            ...convertWebLinkTypeListToDisplayResourceTypeList(convertedList),
          ]);
          setAllNameList((prevList) => [
            ...prevList,
            ...convertedList
              .map((weblink) => weblink.planDocumentName)
              .filter((name) => !removedWebLinks.includes(name)),
          ]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan]);

    const onFileRemove = () => {
      setFile(null);
      setIsRemoved(true);
      setFormData({ ...formData, fileName: '' });
    };

    const toggleConfirmationPopup = () => {
      setConfirmationPopupVisible(null);
    };

    const handleConfirmation = () => {
      if (visibleConfirmation?.webLink) {
        onRemoveWebLinkFromList(visibleConfirmation?.webLink as WebLinkType);
      } else if (visibleConfirmation?.docType) {
        handleRemoveResource(visibleConfirmation?.docType as Option);
      }
    };

    const onFileChange = (value: File) => {
      setIsRemoved(false);
      if (value) {
        const planDocumentName = form.getFieldValue('planDocumentName');
        setFile(value);
        setDocs({ ...docs, [planDocumentName]: value.name });
      }
    };

    const onValidateFails = (validateSetting: string) => {
      setAlertMessage({
        type: 'error',
        message: getValidationMessage(validateSetting),
      });
      setShowAlert(true);
    };

    useImperativeHandle(ref, () => ({
      reset() {
        setRemovedDocuments([]);
        setRemovedWebLinks([]);
      },
    }));

    const onCancel = () => {
      setIsAddNewDocumentOpened(false);
      form.resetFields();
      onFileRemove();
      setIsEdit(false);
      setOnEdit(undefined);
    };

    const updateResourceName = (
      nameList: string[],
      planDocumentName: string
    ): string => {
      const selectedListExist = nameList.filter((file) =>
        file.startsWith(planDocumentName)
      );
      if (!selectedListExist.includes(planDocumentName)) {
        return planDocumentName;
      } else if (selectedListExist.length > 0) {
        const highest = getDocumentNameWithHighestNumber(
          selectedListExist,
          planDocumentName
        );
        return incrementNumberInFileName(highest);
      }
      return planDocumentName;
    };

    const removeDocFromList = (editedResource: string) => {
      const existingFile = selectedFileList.find(
        (file) => file.planDocumentName === editedResource
      );
      if (existingFile) {
        setSelectedFileList((prevSelectedFileList: any) => {
          const updatedFileList = prevSelectedFileList.filter(
            (file: any) => file.planDocumentName !== editedResource
          );

          return updatedFileList;
        });

        setTemporaryAdditionalPlanDocuments((prevTempDocs) => {
          return prevTempDocs.filter(
            (additionalDoc) => additionalDoc.planDocumentName !== editedResource
          );
        });
      } else {
        setAdditionalPlanDocumentTypes(
          additionalPlanDocumentTypes.filter(
            (additionalDoc) => additionalDoc.value !== editedResource
          )
        );
        onRemove(editedResource);
      }
    };

    const handleShowConfirmationPopup = ({
      docType,
      webLink,
    }: {
      docType?: Option;
      webLink?: WebLinkType;
    }) => {
      setConfirmationPopupVisible({ visible: true, docType, webLink: webLink });
    };

    const handleRemoveResource = (docType: Option) => {
      const removeResource = additionalPlanDocumentTypes.find(doc => doc.value === docType.value);
      const filteredList = additionalPlanDocumentTypes.filter((resource: Option) => resource.value !== removeResource?.value);
      setAdditionalPlanDocumentTypes(filteredList);
      const tempDocIndex = temporaryAdditionalPlanDocuments.findIndex(
        (temp) => temp.planDocumentName === docType.value
      );
      const filteredDocList = temporaryAdditionalPlanDocuments.filter((_: FileType, i: number) => i !== tempDocIndex);
      setTemporaryAdditionalPlanDocuments(filteredDocList);
      const newAddedFile = selectedFileList.find(
        (file) => file.planDocumentName === docType.value
      );
      if (newAddedFile) {
        setSelectedFileList((prevItems: Option[]) =>
          prevItems.filter((_: Option, i: number) => i !== tempDocIndex)
        );
      } else {
        onRemove(docType.value);
      }
      resetDisplayList(
          filteredList,
          filteredDocList,
          selectedWebLinkList
      );
      setAllNameList(
        allNameList?.filter((docName) => docName !== docType.value)
      );
      setConfirmationPopupVisible(null);
    };

    const onSave = async () => {
      setDisplayList([]);
      let planDocumentName = form.getFieldValue('planDocumentName')
        ? form.getFieldValue('planDocumentName').trim()
        : NO_NAME;
      const weblink = form.getFieldValue('weblink');
      let updatedAdditionalList: Option[] = cloneDeep(
        additionalPlanDocumentTypes
      );
      let weblinkList = cloneDeep(selectedWebLinkList);
      let updatedFileList: any[] = selectedFileList;
      if (isEdit && onEdit) {
        if (editType === FILE && weblink) {
          removeDocFromList(onEdit);
        } else if (editType === WEBLINK && file) {
          setSelectedWebLinkList(
            selectedWebLinkList.filter(
              (weblink) => weblink.planDocumentName !== onEdit
            )
          );
        }
        if (file) {
          const existingFile = selectedFileList.find(
            (file) => file.planDocumentName === onEdit
          );
          if (existingFile) {
            updatedFileList = selectedFileList.filter(
              (file: any) => file.planDocumentName !== onEdit
            );
            const filteredList = temporaryAdditionalPlanDocuments.filter(
              (additionalDoc) => additionalDoc.planDocumentName !== onEdit
            );
            setTemporaryAdditionalPlanDocuments(filteredList);
            if (
              editedList &&
              !isEmpty(editedList) &&
              editedList.findIndex((doc) => doc.newResourceName === onEdit) !==
                -1
            ) {
              const clonedEditedList = cloneDeep(editedList);
              const updatedList = clonedEditedList.map((editedDoc) =>
                editedDoc.newResourceName === onEdit
                  ? { ...editedDoc, newResourceName: planDocumentName }
                  : editedDoc
              );
              if (setEditedList) setEditedList(updatedList);
            }
          } else {
            if (file.size === 0 && setEditedList) {
              setEditedList((prev: EditDocType[]) => [
                ...prev,
                {
                  newResourceName: planDocumentName,
                  exisingResourceName: onEdit,
                },
              ]);
            } else {
              const savedDoc = additionalPlanDocumentTypes.find(
                (doc) => doc.value === onEdit
              );
              if (savedDoc) onRemove(onEdit);
            }
            updatedAdditionalList = additionalPlanDocumentTypes.filter(
              (additionalDoc) => additionalDoc.value !== onEdit
            );
            setAdditionalPlanDocumentTypes(updatedAdditionalList);
          }
        } else if (weblink) {
          weblinkList = selectedWebLinkList.filter(
            (weblink) => weblink.planDocumentName !== onEdit
          );
          setSelectedWebLinkList(weblinkList);
          updatedAdditionalList = additionalPlanDocumentTypes.filter(
            (additionalDoc) => additionalDoc.value !== onEdit
          );
        }
        const filteredList = allNameList.filter((name) => name !== onEdit);
        setAllNameList(filteredList);
        planDocumentName = updateResourceName(filteredList, planDocumentName);
        setIsEdit(false);
        setOnEdit(undefined);
        setEditType(undefined);
      } else {
        planDocumentName = updateResourceName(allNameList, planDocumentName);
      }

      if (file) {
        const newFile = {
          file: file,
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: planDocumentName.trim(),
        };
        updatedFileList.push(newFile);
        setSelectedFileList(updatedFileList);

        setAllNameList((prevList) => [...prevList, planDocumentName]);
        setDocs({ ...docs, [planDocumentName]: file.name });

        const tempDocListIndex = temporaryAdditionalPlanDocuments.findIndex(
          (item) =>
            item.planDocumentName?.toString() === planDocumentName?.toString()
        );
        const planDocListIndex = additionalPlanDocumentTypes.findIndex(
          (item) => item.value?.toString() === planDocumentName?.toString()
        );
        if (tempDocListIndex !== -1) {
          const docList = cloneDeep(temporaryAdditionalPlanDocuments);
          docList[tempDocListIndex].planDocumentName = planDocumentName;
          setTemporaryAdditionalPlanDocuments(docList);
        } else if (planDocListIndex !== -1) {
          updatedAdditionalList = cloneDeep(additionalPlanDocumentTypes);
          updatedAdditionalList[planDocListIndex].value = planDocumentName;
          setAdditionalPlanDocumentTypes(updatedAdditionalList);
        } else {
          setTemporaryAdditionalPlanDocuments((additionalTypes: any) => [
            ...additionalTypes,
            newFile,
          ]);
        }
        weblinkList = selectedWebLinkList?.filter(
          (item) => item.planDocumentName?.toString() !== onEdit?.toString()
        );
        setSelectedWebLinkList(weblinkList);

        setIsAddNewDocumentOpened(false);
      } else if (weblink) {
        if (isValidWeblink(weblink)) {
          const existingWebLinkIndex = weblinkList.findIndex(
            (link) => link.planDocumentName === planDocumentName
          );
          if (existingWebLinkIndex !== -1) {
            weblinkList[existingWebLinkIndex].weblink = weblink;
            setSelectedWebLinkList(weblinkList);
          } else {
            weblinkList.push({
              weblink: weblink,
              planDocumentName: planDocumentName.trim(),
            });
            setSelectedWebLinkList(weblinkList);
          }
          setAllNameList((prevList) => [...prevList, planDocumentName]);
          setIsAddNewDocumentOpened(false);
        } else
          setAlertMessage({
            type: 'error',
            message: getValidationMessage(INVALID_WEBLINK),
          });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Please select a file to upload',
        });
        setShowAlert(true);
      }

      resetDisplayList(updatedAdditionalList, updatedFileList, weblinkList);

      form.resetFields();
      onFileRemove();
      setAdditionalResourceType('');
    };

    const resetDisplayList = (
      additionalResourceList: Option[],
      selectedList: FileType[],
      weblinkList: WebLinkType[]
    ) => {
      setDisplayList([]);
      setDisplayList((prevList: DisplayResourceType[]) => [
        ...prevList,
        ...convertOptionListToDisplayResourceTypeList(additionalResourceList),
        ...convertSelectedListToDisplayResourceTypeList(selectedList),
        ...convertWebLinkTypeListToDisplayResourceTypeList(weblinkList),
      ]);
    };

    const onRemove = (docType: string) => {
      setRemovedDocuments((prev) => [...prev, docType]);

      const isInAdditionalPlanDocumentTypes = additionalPlanDocumentTypes?.some(
        (doc) => doc?.value === docType
      );
      // Only set the state if docType is in additionalPlanDocumentTypes
      if (isInAdditionalPlanDocumentTypes) {
        setIsDocRemoved((prevState: any) => ({
          ...prevState,
          [docType]: true,
        }));
      }

      if (!isEmpty(docs) && !isEmpty(docs[docType])) {
        const copy = cloneDeep(docs);
        delete copy[docType];
        setDocs(copy);
      }
    };
    const onRemoveWebLinkFromList = (webLink: WebLinkType) => {
      setRemovedWebLinks((prev) => [...prev, webLink.planDocumentName]);
      const webLinkIndex = selectedWebLinkList.findIndex(
        (weblink) => weblink.planDocumentName === webLink.planDocumentName
      );
      const filteredList = selectedWebLinkList.filter(
        (_: any, i: any) => i !== webLinkIndex
      );
      setSelectedWebLinkList(filteredList);
      setAllNameList(
        allNameList?.filter((docName) => docName !== webLink.planDocumentName)
      );
      resetDisplayList(
        additionalPlanDocumentTypes,
        selectedFileList,
        filteredList
      );
      setConfirmationPopupVisible(null);
    };

    const temporaryDocumentEditClick = (fileType: FileType) => {
      // Set initial form values first
      form.setFieldsValue({ weblink: undefined });

      // Use a promise to enforce order for setting form fields and file
      Promise.resolve().then(() => {
        form.setFieldsValue({
          planDocumentName: !fileType.planDocumentName.startsWith(NO_NAME)
            ? fileType.planDocumentName
            : undefined,
        });

        setFile(fileType.file);
        setOnEdit(fileType.planDocumentName);
        setEditType(FILE);
      });

      // Perform the rest of the state updates
      setIsEdit(true);
      setIsAddNewDocumentOpened(true);
      setAdditionalResourceType('file');
    };

    const renderDisplayList = (
      resource: DisplayResourceType,
      index: number
    ) => {
      if (resource.type === FILE) {
        if (resource.value instanceof File) {
          return renderTemporaryList(
            {
              planDocumentName: resource.name,
              file: resource.value,
              docType: PLAN_ADDITIONAL_DOCUMENT,
            },
            index
          );
        } else {
          return renderList(
            { value: resource.name, label: resource.value },
            index
          );
        }
      } else {
        return renderWebList(
          { weblink: resource.value, planDocumentName: resource.name },
          index
        );
      }
    };

    const renderTemporaryList = (fileType: FileType, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() =>
                  handleShowConfirmationPopup({
                    docType: {
                      value: fileType.planDocumentName,
                      label: fileType.file.name,
                    },
                  })
                }
                icon={<IconRemoveDocument width={24} />}
              />
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {fileType.planDocumentName.startsWith(NO_NAME)
                  ? fileType.file.name
                  : fileType.planDocumentName}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton onClick={() => temporaryDocumentEditClick(fileType)}>
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };

    const renderList = (docType: Option, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => handleShowConfirmationPopup({ docType })}
                icon={<IconRemoveDocument width={24} />}
              ></LinkButton>
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {docType.value.startsWith(NO_NAME)
                  ? docType.label
                  : docType.value}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  // Set initial form values first
                  form.setFieldsValue({ weblink: undefined });

                  // Use a promise to enforce order for setting form fields and file
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: !docType.value.startsWith(NO_NAME)
                        ? docType.value
                        : undefined,
                    });

                    const blobUrl = get(
                      docs,
                      [docType.value, 'blobUrl'],
                      undefined
                    );
                    const fileName = get(
                      docs,
                      [docType.value, 'fileName'],
                      undefined
                    );
                    let file;
                    const savedFileName = get(docs, docType.value);

                    if (savedFileName) {
                      file = new File([''], savedFileName);
                    }
                    if (blobUrl) {
                      file = new File([blobUrl], fileName);
                    }

                    setFile(file);
                    setOnEdit(docType.value);
                    setEditType(FILE);
                  });

                  // Perform the rest of the state updates
                  setIsEdit(true);
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('file');
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };
    const renderWebList = (webLink: WebLinkType, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => handleShowConfirmationPopup({ webLink })}
                icon={<IconRemoveDocument width={24} />}
              />
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {webLink.planDocumentName == null ||
                webLink.planDocumentName === 'undefined' ||
                webLink.planDocumentName.startsWith(NO_NAME)
                  ? webLink.weblink
                  : webLink.planDocumentName}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  setFile(null);
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: webLink.planDocumentName.startsWith(
                        NO_NAME
                      )
                        ? webLink.weblink
                        : webLink.planDocumentName,
                    });
                  });

                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      weblink: webLink.weblink,
                    });
                  });
                  form.setFieldsValue({ webLink: webLink.weblink });
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('webLink');
                  setIsEdit(true);
                  setEditType(WEBLINK);
                  setOnEdit(webLink.planDocumentName);
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };

    useEffect(() => {
      if (!isEmpty(removedDocuments)) {
        let removedCount = 0;
        setSelectedFileList(() =>
          selectedFileList?.filter((doc: any) => {
            if (
              doc?.docType === PLAN_ADDITIONAL_DOCUMENT &&
              removedCount < 1 &&
              removedDocuments?.includes(doc?.file?.name)
            ) {
              removedCount++;
              return false; // Filter out the first matching document
            }
            return true; // Keep the rest
          })
        );
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removedDocuments]);

    return (
      <>
        {showAlert && (
          <div className={styles.alertWrapper}>
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={() => setShowAlert(false)}
            />
          </div>
        )}
        <LinkButton
          className={styles.addAdditionalResource}
          icon={<IconAddDocument width={24} />}
          disabled={isAddNewDocumentFormOpened}
          onClick={() => {
            setIsAddNewDocumentOpened(true);
          }}
        >
          Add Additional Resources
        </LinkButton>
        {displayList.map(renderDisplayList)}
        {isAddNewDocumentFormOpened && (
          <PlanResources
            ref={ref}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            setFormData={setFormData}
            formData={formData}
            form={form}
            isRemoved={isRemoved}
            isCloseConfirmed={isCloseConfirmed}
            additionalResourceType={additionalResourceType}
            setAdditionalResourceType={setAdditionalResourceType}
            onValidateFails={onValidateFails}
            invalidField={invalidField}
            setIsAdditionalInfoValid={setInvalidField}
            planId={plan?.id}
            benefitKind={benefitKind}
            onSave={onSave}
            onCancel={onCancel}
            file={file}
          />
        )}
        <ConfirmationDialog
          title="Delete Resource"
          okText="Delete Resource"
          cancelText="Cancel"
          onCancel={toggleConfirmationPopup}
          onOk={handleConfirmation}
          visible={visibleConfirmation?.visible}
          flipFooterOrder={true}
        >
          <p className={styles.warningConfirmation}>{removeResourceWarning}</p>
        </ConfirmationDialog>
      </>
    );
  }
);
AdditionalPlanResources.displayName = 'AdditionalPlanResources';
export default AdditionalPlanResources;
