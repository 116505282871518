import { http as axios } from 'utils/httpUtil';
import { baseApi } from 'utils/apiUtil';
import { modifyAndEncodeBenefitClassNamesToHandleComma } from 'utils/commonUtil';

const planUrl = baseApi + '/v1/plans/work-life-plans';

export const getPlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return axios.get(`${planUrl}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'sub-category': 'TIME_OFF',
      'benefit-classes':
        modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(','),
    },
  });
};

export const getFamilyAndLeavePlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return axios.get(`${planUrl}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'sub-category': 'FAMILY',
      'benefit-classes':
        modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(','),
    },
  });
};
