import { http as axios } from 'utils/httpUtil';
import { baseApi } from 'utils/apiUtil';
import { modifyAndEncodeBenefitClassNamesToHandleComma } from 'utils/commonUtil';

const taxAdvantagedUrl = `${baseApi}/v1/plans/tax-advantage-accounts`;
const hsaAssociatedMedicals = `${baseApi}/v1/plans/medicals/hsa`;
const hraAssociatedMedicals = `${baseApi}/v1/plans/medicals/hra`;
const hraAssociatedDental = `${baseApi}/v1/plans/dentals/hra`;
const hraAssociatedVision = `${baseApi}/v1/plans/visions/hra`;

export const getTaxAdvantagedPlanList = (
  employerId: string,
  planYearId: string,
  benefitKinds: string[],
  benefitClasses: string[]
) => {
  return axios.get(`${taxAdvantagedUrl}/roster`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': benefitKinds.join(','),
      'benefit-classes': benefitClasses
        ? modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(
            ','
          )
        : '',
    },
  });
};

export const getAllTaxAdvantagedPlans = (
  employerId: string,
  planYearId: string,
  benefitKinds: string[],
  benefitClasses: string[]
) => {
  return axios.get(`${taxAdvantagedUrl}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': benefitKinds.join(','),
      'benefit-classes': benefitClasses
        ? modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(
            ','
          )
        : '',
    },
  });
};

export const getAllHSAAscociatedMedicals = (
  employerId: string,
  planYearId: string,
  benefitKind: string
) => {
  return axios.get(`${hsaAssociatedMedicals}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      benefitKind: benefitKind,
    },
  });
};

export const getAllHRAAscociatedMedicals = (
  employerId: string,
  planYearId: string
) => {
  return axios.get(`${hraAssociatedMedicals}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
    },
  });
};

export const getAllHRAAscociatedDentals = (
  employerId: string,
  planYearId: string
) => {
  return axios.get(`${hraAssociatedDental}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
    },
  });
};

export const getAllHRAAscociatedVisions = (
  employerId: string,
  planYearId: string
) => {
  return axios.get(`${hraAssociatedVision}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
    },
  });
};
