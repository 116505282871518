import { http as axios } from 'utils/httpUtil';

import { baseApi } from 'utils/apiUtil';
import { modifyAndEncodeBenefitClassNamesToHandleComma } from 'utils/commonUtil';
const planUrl = baseApi + '/v1/plans/retirements';

export const getFourOOneKPlanList = async (
  employerId: string,
  planYearId: string,
  benefitClasses: string[]
) => {
  return axios.get(`${planUrl}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-classes':
        modifyAndEncodeBenefitClassNamesToHandleComma(benefitClasses).join(','),
    },
  });
};
