import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import InputForm from 'components/InputForm/InputForm';
import {
  AdditionalResourcesRadioTypes,
  PLAN_ADDITIONAL_DOCUMENT,
} from 'modules/resources/constants';

import { EMPTY_MESSAGE } from 'constants/commonConstants';
import TextButton from 'components/buttons/TextButton/TextButton';

import { isValidWeblink, MIME_TYPES } from 'utils/fileUtil';
import AdditionalDocumentUploader from 'components/AdditionalDocumentUploader/AdditionalDocumentUploader';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import { getPlanDocument } from 'modules/auth/services/PlanService';
import styles from './planResources.module.less';

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';
type PlanResourcesProps = {
  onFileChange: Function;
  onFileRemove: Function;
  setFormData: Function;
  formData: any;
  form: any;
  isRemoved: boolean;
  isUploading?: boolean;
  planId?: string;
  benefitKind?: string;
  isCloseConfirmed: boolean;
  additionalResourceType: any;
  setAdditionalResourceType: Function;
  onValidateFails?: (validateSetting: string) => void;
  invalidField?: InvalidField;
  setIsAdditionalInfoValid: Function;
  onFileRemoveAction?: Function;
  onSave: Function;
  onCancel: Function;
  file: File;
};

const DOC_UPLOAD_BENEFIT_KIND = [
  benefitCategory.MEDICAL.value,
  benefitCategory.DENTAL.value,
  benefitCategory.VISION.value,
  benefitCategory.BASIC_LIFE.value,
  benefitCategory.BASIC_ADD.value,
  benefitCategory.BASIC_LIFE_AND_ADD.value,
  benefitCategory.STD.value,
  benefitCategory.LTD.value,
];

const PlanResources = forwardRef((props: PlanResourcesProps, ref) => {
  const {
    onFileChange,
    onFileRemove,
    setFormData,
    formData,
    form,
    isRemoved,
    planId,
    benefitKind,
    isUploading,
    isCloseConfirmed,
    additionalResourceType,
    setAdditionalResourceType,
    onValidateFails,
    invalidField,
    setIsAdditionalInfoValid,
    onFileRemoveAction,
    onSave,
    onCancel,
    file,
  } = props;

  const documenUploaderRef = useRef<any>();
  const restrictedChars = ['.', '%', '&', '#', '?'];

  useEffect(() => {
    if (isCloseConfirmed) {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
      form.resetFields();
    }
  }, [form, isCloseConfirmed]);

  const onInputChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
    setIsAdditionalInfoValid('');
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData.getData('Text');
    if (restrictedChars.some((char) => text.includes(char))) {
      e.preventDefault();
    }
  };

  const removeFile = () => {
    const { current = {} } = documenUploaderRef;
    const { reset = () => {} } = current;
    reset();
    onFileRemove();
  };

  const handleClick = (val: CheckboxChangeEvent) => {
    if (val.target.value === additionalResourceType) {
      setAdditionalResourceType('');
      removeFile();
      form.setFieldsValue({ weblink: '' });
    } else {
      removeFile();
      form.setFieldsValue({ weblink: '' });
      setAdditionalResourceType(val.target.value);
    }
    setIsAdditionalInfoValid('');
  };

  useImperativeHandle(ref, () => ({
    reset() {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
    },
  }));

  const downloadFileObj = async (
    planId: string | undefined,
    file: string,
    fileName: string
  ) => {
    if (planId) {
      const response = await getPlanDocument(
        planId,
        file,
        benefitKind as string
      );
      const blob = new Blob([response.data], {
        type: response?.data?.type || 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const aDom = document.createElement('a');
      aDom.setAttribute('style', 'display:none');
      aDom.setAttribute('href', url);
      aDom.setAttribute('download', fileName);
      document.body.appendChild(aDom);
      aDom.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(aDom);
    }
  };

  return (
    <div
      className={`${styles.wrapperForm} ${
        !planId && benefitKind && !DOC_UPLOAD_BENEFIT_KIND.includes(benefitKind)
          ? styles.addFormWrapper
          : ''
      }`}
    >
      <InputForm form={form} onValuesChange={onInputChange}>
        <Row>
          <Col span={24}>
            <Form.Item
              className={styles.planNameWrapper}
              name="planDocumentName"
              label="Name"
              rules={[
                {
                  required: false,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input
                data-cy="planDocumentName"
                className={styles.fileNameInput}
                onKeyPress={(e) => {
                  if (restrictedChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={handlePaste}
              />
            </Form.Item>
          </Col>
        </Row>
        <Radio.Group name="radiogroup" value={additionalResourceType}>
          <Row className={styles.docWrapper} justify={'space-between'}>
            <Col span={12}>
              <Row gutter={48} className={styles.rowContainer}>
                <Col span={6}>
                  <Radio
                    value={AdditionalResourcesRadioTypes.FILE}
                    onChange={handleClick}
                  >
                    <div
                      className={
                        invalidField === 'documentInvalid'
                          ? styles.errorOption
                          : styles.optionalTitle
                      }
                    >
                      File{' '}
                    </div>
                  </Radio>
                </Col>
                <Col>
                  <div className={styles.fileUploader}>
                    <AdditionalDocumentUploader
                      ref={documenUploaderRef}
                      onChange={(file: File) => {
                        onFileChange(file);
                      }}
                      onRemove={() => {
                        removeFile();
                        onFileRemoveAction?.();
                      }}
                      isRemoved={isRemoved}
                      isLoading={isUploading}
                      allowedFileTypes={[
                        MIME_TYPES.PDF,
                        MIME_TYPES.DOC,
                        MIME_TYPES.DOCX,
                        MIME_TYPES.XLS,
                        MIME_TYPES.XLSX,
                        MIME_TYPES.PNG,
                        MIME_TYPES.JPG,
                        MIME_TYPES.JPEG,
                      ]}
                      maxFileSizeMB={100}
                      defaultFile={file}
                      disabled={
                        additionalResourceType !==
                        AdditionalResourcesRadioTypes.FILE
                      }
                      onFileNameClick={(file: File) =>
                        downloadFileObj(
                          planId,
                          PLAN_ADDITIONAL_DOCUMENT,
                          file.name
                        )
                      }
                      onValidateFails={onValidateFails}
                      wrapperClassName={styles.fileUploaderWrapper}
                      isAdditionalFileUploader={true}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={48} className={styles.rowContainer}>
                <Col span={6}>
                  <div className={styles.weblink}>
                    <Row>
                      <Radio
                        value={AdditionalResourcesRadioTypes.WEBLINK}
                        onChange={handleClick}
                      >
                        <div
                          className={
                            invalidField === 'webLinkInvalid'
                              ? styles.errorOptionWeb
                              : styles.optionalTitleWeb
                          }
                        >
                          Weblink
                        </div>
                      </Radio>
                    </Row>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item name="weblink" className={styles.weblinkInput}>
                      <Input
                        disabled={
                          additionalResourceType !==
                          AdditionalResourcesRadioTypes.WEBLINK
                        }
                        className={styles.weblinkInput}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Radio.Group>
      </InputForm>
      <Row className={styles.docBtnWrapper}>
        <Col span={4}>
          <TextButton
            label="Cancel"
            className={styles.cancelButtonWrapper}
            onClick={() => {
              setAdditionalResourceType(null);
              onCancel();
            }}
            type="default"
          />
        </Col>
        <Col>
          <TextButton
            label="Save"
            className={styles.saveButtonWrapper}
            onClick={() => {
              const weblink = form.getFieldValue('weblink');
              if (additionalResourceType === 'file' && !file) {
                setIsAdditionalInfoValid('documentInvalid');
              } else if (
                additionalResourceType === 'webLink' &&
                !isValidWeblink(weblink)
              ) {
                setIsAdditionalInfoValid('webLinkInvalid');
              } else {
                onSave();
              }
            }}
            type="primary"
          />
        </Col>
      </Row>
    </div>
  );
});
PlanResources.displayName = 'PlanResources';
export default PlanResources;
